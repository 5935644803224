<template>
  <div
    class="main-article"
    element-loading-text="正在生成页面"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <div class="printbtn">
      <p style="color: red">注意事项：</p>
      <p>如果需要导出PDF文件，则在页面打印设置中,选择保存到PDF文件</p>
    </div>
    <div class="tc-report-main">
      <div class="content">
        <div class="report-title">
          <h1>精神科临床常用量表测验报告单</h1>
        </div>
        <div class="title-info">
          <div class="unit">
            <span>使用单位:</span>
            <span> {{ config.unit_name }}</span>
          </div>
          <div class="user-info">
            <span>姓名:{{ form.name }}</span>
            <span>性别:{{ form.sex }}</span>
            <span>年龄:{{ form.ages }}岁</span>
            <span>教育:{{ form.culture }}</span>
            <!-- <span>婚姻:{{ form.name }}</span> -->
            <!-- <span>住院号:{{ form.name }}</span>
              <span>病区:{{ form.name }}</span> -->
          </div>
        </div>
        <div class="table">
          <table border="1" width="100%" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th width="30%">量表</th>
                <th>项目</th>
                <th>记分</th>
                <th>程度</th>
              </tr>
            </thead>
            <tbody
              align="center"
              v-for="(item, index) of tableInfoList"
              :key="index"
            >
                <tr v-for="(subitem, cindex) of item" :key="cindex">
                  <template v-if="subitem.name!='总评'">
                  <td width="30%" :rowspan="item.length" v-if="cindex == 0">
                    {{ subitem.measure_title }}
                  </td>
                  <td width="30%">{{ subitem.name }}</td>
                  <td width="20%">{{ subitem.score }}</td>
                  <td width="20%">{{ subitem.mark }}</td>
                  </template>
                </tr>
            </tbody>
          </table>
        </div>
        <div
          style="border-top: 1px solid #000; padding: 15px; text-align: center"
        >
          <h2>临床综合分析</h2>
        </div>
        <div class="table2">
          <!-- <div v-for="(item, index) of tableInfoList" :key="index">
              <div>{{ item[0].measure_title }}:</div>
              <div style="display: flex; flex-wrap: wrap">
                <div v-for="(subitem, cindex) of item" :key="cindex">
                  <div>{{ cindex + 1 }}、{{ subitem.comment }}</div>
                </div>
              </div>
            </div> -->
            <div v-for="(item, index) of baseArr" :key="index">
              <div><span>{{index+1}}、</span>{{item}}</div>
              <!-- <div
                v-html="index + 1 + '、' + removeHTMLTag(item.comment)"
              ></div> -->
              <!-- <div v-for="(subitem, _index) of item" :key="_index">
                <div v-if="_index==item.length-1&&subitem.comment"><span>{{index+1}}、</span>{{ subitem.comment}}</div>
              </div> -->
            </div>
        </div>
        <div style="text-align: center; font-size: 20px">(本报告仅供参考)</div>
        <div style="text-align: right; font-size: 20px;padding:50px 200px">
          报告人签名：
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadReport } from "@/api/report.js";
export default {
  data() {
    return {
      // 排序
      baseArr:[],
      uid: {},
      // 表数据
      tableInfoList: [],
      itemlist: [],
      form: {},
      measure_title: "",
    };
  },
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      // console.log("单");
      data.id = this.$route.query.id;
      this.uid.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      // console.log("多");
      data.id = this.$route.query.ids;
      this.uid.ids = this.$route.query.ids;
      // console.log(this.$route.query.ids.split(",").length == 1);
      if (this.$route.query.ids.split(",").length == 1) {
      }
    }
    this.loadReport(data);

    // 发起请求渲染报告
  },
  computed: {
    removeHTMLTag() {
      return function (str) {
        // str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
        // str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
        // str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
        // str = str.replace(/ /gi, ""); //去掉
        str = str.replace(/&nbsp;/gi, "");
        return str;
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    loadReport(data) {
      loadReport(data).then((res) => {
        if(res.code==400200){
          let dataarr =[...res.data]
          dataarr.filter(item=>{
            if(item.base_results.comment){
                this.baseArr.push(item.base_results.comment)
            }
          })
        }
        
        if (Object.prototype.toString.call(res.data) == "[object Object]") {
          this.form = res.data.customer;
          if (
            Object.prototype.toString.call(res.data.base_results) ==
            "[object Object]"
          ) {
            if (
              Object.prototype.toString.call(res.data.factor_results) ==
              "[object Object]"
            ) {
              let obj = Object.assign(
                res.data.base_results,
                res.data.factor_results
              );
              obj.measure_title = res.data.measure_title;
              this.itemlist.push(obj);
            } else {
              let newarr = Array.from(res.data.factor_results);
              newarr.forEach((item) => {
                item.measure_title = res.data.measure_title;
              });
              let obj = Object.assign({}, res.data.base_results);
              obj.measure_title = res.data.measure_title;
              newarr.push(obj);
              this.itemlist.push(...newarr);
              debugger;
            }
          } else {
            if (
              Object.prototype.toString.call(res.data.factor_results) ==
              "[object Object]"
            ) {
              let newarr = Array.from(res.data.base_results);
              newarr.forEach((item) => {
                item.measure_title = res.data.measure_title;
              });
              let obj = Object.assign({}, res.data.factor_results);
              obj.measure_title = res.data.measure_title;
              newarr.push(obj);
              this.itemlist.push(...newarr);
              debugger;
            } else {
              let newarr2 = res.data.base_results.concat(
                res.data.factor_results
              );

              // 阳性与阴性症状量表
              if (res.data.measure_title == "阳性与阴性症状量表(PANSS)") {
                newarr2 = newarr2.slice(18, 24);
              }

              let newarr = newarr2.filter((item, index, arr) => {
                return (item.measure_title = res.data.measure_title);
              });

              this.itemlist.push(...newarr);
            }
          }
        } else {
          this.form = res.data[0].customer;
          for (let i of res.data) {
            if (
              Object.prototype.toString.call(i.base_results) ==
              "[object Object]"
            ) {
              if (
                Object.prototype.toString.call(i.factor_results) ==
                "[object Object]"
              ) {
                let obj = Object.assign(i.base_results, i.factor_results);
                obj.measure_title = i.measure_title;
                this.itemlist.push(obj);
              } else {
                let newarr = Array.from(i.factor_results);
                newarr.forEach((item) => {
                  item.measure_title = i.measure_title;
                });
                let obj = Object.assign({}, i.base_results);
                obj.measure_title = i.measure_title;
                newarr.push(obj);
                this.itemlist.push(...newarr);
                // debugger;
              }
            } else {
              if (
                Object.prototype.toString.call(i.factor_results) ==
                "[object Object]"
              ) {
                let newarr = Array.from(i.base_results);
                newarr.forEach((item) => {
                  item.measure_title = i.measure_title;
                });
                let obj = Object.assign({}, i.factor_results);
                obj.measure_title = i.measure_title;
                newarr.push(obj);
                this.itemlist.push(...newarr);
              } else {
                let newarr2 = i.base_results.concat(i.factor_results);
                // 阳性与阴性症状量表
                if (i.measure_title == "阳性与阴性症状量表(PANSS)") {
                  newarr2 = newarr2.slice(18, 24);
                }

                let newarr = newarr2.filter((item, index, arr) => {
                  return (item.measure_title = i.measure_title);
                });
                this.itemlist.push(...newarr);
              }
            }
          }
        }
        let sorted = this.groupBy(this.itemlist, function (item) {
          return [item.measure_title];
        });
        this.tableInfoList = sorted;

        this.tableInfoList.map(item=>{
          item.map(_item=>{
            if(_item.comment.indexOf("&nbsp") != -1){
                _item.comment=""
            }
              return _item
          })
          return item 
        })

        // 处理表格数据
        this.tableInfoList.filter((item,index)=>{
          item.splice(item.length-1,1)
          return item
        })
        let arr = [];
        for (let i of sorted) {
          for (let j of i) {
            if (j.name == "总分") {
              let obj = {};
              obj.name = j.name;
              obj.comment = j.comment;
              arr.push(obj);
            }
          }
        }
        this.tableInfoList2 = arr;
        console.log(sorted, "sorted");
      });
    },
    //分组
    groupBy(array, f) {
      var groups = {};
      array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    // 打印
    fetchData() {
      setTimeout(() => {
        this.fullscreenLoading = false;
        // 视图更新完成后执行
        this.$nextTick(() => {
          window.print();
        });
      }, 1500);
    },
  },
};
</script>

<style lang="less">
@media print {
  .printbtn {
    display: none;
  }
  .PageNext {
    page-break-after: always;
  }
  .seal {
    page-break-inside: avoid;
  }
  // .page{
  //   margin: 100px 0;
  // }
}
.printbtn {
  position: absolute;
  top: 50px;
  left: 20px;
}
.main-article {
  padding: 20px;
  display: block;
  background: #fff;
}
// 报告内容部分
.content {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 15px;
  box-sizing: border-box;
  // 报表头部
  .report-title {
    font-size: 16px;
    text-align: center;
  }
  .title-info {
    .unit {
      font-size: 20px;
      color: #000;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
  .user-info {
    display: flex;
    justify-content: space-between;
  }
  //   用户信息
  .userInfo {
    margin-top: 20px;
    table {
      margin: auto;
      width: 100%;
      border-top: 1px black dashed;
      border-left: 1px black dashed;
      tr {
        width: 100%;
        // font-size: 16px;
        font-weight: 700;
        td {
          padding: 5px;
          border-bottom: 1px black dashed;
          border-right: 1px black dashed;
          &:nth-child(1) {
            width: 220px;
          }
          &:nth-child(2) {
            width: 180px;
          }
          &:nth-child(3) {
            min-width: 300px;
          }
          span {
            // font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
  //   测试结果标题.
  .test-msg {
    padding-left: 0px;
    margin-top: 10px;
    font-size: 25px;
    color: orange;
    text-shadow: black 2px 1px 1px;
    font-weight: 700;
  }
  //    图表
  .chart {
    margin-top: 10px;
    height: 350px;
    #chart-report {
      margin: 0 auto;
      //   border: black 1px solid;
    }
  }
  //   表格
  .table {
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px 0;
    // border-top: 1px black solid;
    // border-bottom: 1px black solid;
    .table-main {
      .one,
      .two {
        width: 100%;
        border-top: 1px black dashed;
        border-left: 1px black dashed;
        tr {
          width: 100%;
          th {
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
          }
          td {
            text-align: center;
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
            font-size: 14px;
            &:nth-child(1) {
              // width: 180px;
            }
            &:nth-child(2) {
              // width: 150px;
            }
            &:nth-child(3) {
              min-width: 40px;
              text-align: center;
            }
            .table-icon {
              padding-left: 5px;
            }
          }
        }
      }
      .one {
      }
      .results-two {
        width: 100%;
        border-top: 1px dashed black;
        border-left: 1px dashed black;
        tr {
          th {
            padding: 5px 0;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            &:nth-child(1) {
              width: 160px;
            }
            &:nth-child(2) {
              width: 180px;
            }
            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }
            &:nth-child(5) {
              width: 180px;
            }
            &:nth-child(6) {
              width: 50px;
            }
          }
          td {
            box-sizing: border-box;
            padding: 2px 5px 2px 5px;
            vertical-align: middle;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            text-align: center;
            &:nth-child(1) {
              width: 160px;
            }
            &:nth-child(2) {
              width: 180px;
            }
            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }
            &:nth-child(5) {
              width: 180px;
            }
            &:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
  .table2 {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 20px;
  }

  //   健康状态
  .condition {
    margin-top: 10px;
    border-bottom: 1px black solid;
    padding-bottom: 14px;
    table {
      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }
      .condition-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }
  //   每项分析
  .analyze {
    margin-top: 20px;
    padding-bottom: 10px;

    table {
      padding: 10px 0;
      border-bottom: 1px black solid;
      width: 100%;
      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }
      .analyze-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }
  // 签名
  .signature {
    position: relative;
    text-align: right;
    margin-top: 40px;
    .title {
      font-weight: 700;
      .signature_img {
        display: inline-block;
        min-width: 100px;
      }
    }
    .data {
      margin-left: 20px;
    }
    img {
      // position: absolute;
      // top: -40px;
      // left: 284px;
    }
  }
}
// 明尼苏达
.minnesota {
  transform: translateX(-55px);
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .remind {
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    padding: 5px 0;
  }
  .el-divider--horizontal {
    margin: 5px 0;
  }
  .el-divider {
    background-color: rgb(133, 133, 133);
  }
  .mmpi {
    margin-top: 40px;
    .mmpi_table {
      margin-top: 20px;
      table:nth-child(1) {
        width: 100%;
        font-size: 14px;
        tr:nth-child(1) {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              // text-align: center;
              height: 120px;
              writing-mode: vertical-lr;
              letter-spacing: 8px;
            }
          }
        }
        tr:nth-child(2) {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      table:nth-child(3) {
        width: 100%;
        font-size: 14px;
        tr {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      .mmpi_summarize {
        padding: 10px 0;
      }
    }
  }
  .t {
    margin-top: 40px;
    .t_table {
      margin-top: 20px;
      > table:nth-child(1) {
        width: 100%;
        font-size: 14px;
        tr {
          display: flex;
          > td {
            flex: 1;
            display: flex;
            justify-content: center;
            > span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      > table:nth-child(2) {
        width: 100%;
        font-size: 14px;
        border: black 1px solid;
        border-collapse: collapse;
        tr {
          &:nth-child(1) {
            td:nth-child(4) {
              // display: flex;
              span {
                display: inline-block;
                width: 9%;
                justify-content: space-around;
                position: relative;
                text-align: center;
                &::after {
                  font-size: 17px;
                  content: "|";
                  position: absolute;
                  bottom: -16px;
                  right: 9px;
                  z-index: 999;
                }
                &::before {
                  font-size: 10px;
                  content: "|||||||||";
                  position: absolute;
                  bottom: -11px;
                  right: -3px;
                  z-index: 999;
                }
              }
            }
          }
          td {
            text-align: center;
            border-right: black 1px solid;
            border-bottom: black 1px solid;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 50px;
            }
            &:nth-child(3) {
              width: 180px;
            }
            &:nth-child(4) {
              position: relative;
              text-align: left;
              font-size: 10px;
            }
            &:nth-child(5) {
              width: 150px;
            }
          }
        }
        .cursor {
          color: black;
          display: inline-block;
          position: absolute;
          .el-icon-caret-top {
            font-size: 20px;
          }
        }
      }
      .forecast {
        margin-top: 30px;
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 5px 5px;
          caption {
            font-size: 16px;
            font-weight: 700;
            padding: 10px 0;
          }
          tr {
            &:nth-child(2) {
              font-weight: 700;
              td {
                &:nth-child(1) {
                  width: 120px;
                }
              }
            }
            td {
              width: 50px;
              text-align: right;
            }
          }
        }
      }
    }
  }
  .conclusion {
    margin-top: 40px;
    table {
      width: 100%;
      border-collapse: collapse;
      caption {
        padding: 5px 0;
        font-weight: 700;
        font-size: 18px;
      }
      tr {
        th {
          border-top: 1px black solid;
          border-bottom: 1px black solid;
          padding: 5px 0;
          &:nth-child(1) {
            width: 200px;
            text-align: center;
          }
          &:nth-child(2) {
            width: 150px;
            text-align: center;
          }
          &:nth-child(3) {
          }
        }
        td {
          font-size: 14px;
          padding: 5px 0;
          &:nth-child(1) {
          }
          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }
  }
  .auxiliary {
    margin-top: 40px;
    .auxiliary_title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }
    .auxiliary_main {
      .auxiliary_item {
        padding: 10px 0;
        .item_title {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }
  .describe {
    margin-top: 40px;
    .describe_main {
      .describe_content_title {
        font-size: 16px;
        text-align: center;
        p {
          margin: 30px 0px 9px 0px;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .describe_content {
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }
  .zDescribe {
    margin-top: 60px;
    .zDescribe_title {
      font-weight: 700;
      text-align: center;
    }
    .zDescribe_content {
      margin-top: 40px;
      font-size: 14px;
    }
    .zDescribe_msg {
      margin-top: 50px;
      p {
        font-weight: 700;
        span {
          font-weight: 100;
        }
      }
    }
  }
  .suggest {
    border-top: 1px black solid;
    margin-bottom: 5px;
    padding: 5px 0;
    .suggest_msg {
      font-weight: 700;
    }
    .suggest_content {
      height: 200px;
    }
  }
}
</style>
